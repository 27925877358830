
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import type { SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'

export default defineComponent({
  components: {
    TmStatus,
    TmColoredUl,
    TmTooltip,
    InfoCard,
    InfoCardRow,
  },
  props: {
    numberLine: {
      type: Object as PropType<SenderNumberLine>,
    },
  },
})
