
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import type { SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    InfoCard,
    InfoCardRow,
  },
  props: {
    numberLine: {
      type: Object as PropType<SenderNumberLine>,
      required: true,
    },
  },
  setup(props) {
    const copyLink = ref('https://wa.me/3725232960')
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(copyLink.value)
      setTimeout(() => {
        copiedStatus.value = false
      }, 3000)
    }

    const { openWhatsAppQrCodeModal } = useChatsSettings()
    const viewQr = () => {
      openWhatsAppQrCodeModal(props.numberLine.number.name, props.numberLine.title)
    }

    return {
      copyLink,
      copiedStatus,
      copyText,
      viewQr,
    }
  },
})
