
import { defineComponent, ref } from 'vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import ChatWhatsAppNumberGeneral from '@/components/pages/chats/settings/whatsApp/number/ChatWhatsAppNumberGeneral.vue'
import ChatWhatsAppNumberSharing from '@/components/pages/chats/settings/whatsApp/number/ChatWhatsAppNumberSharing.vue'
import ChatWhatsAppNumberTerms from '@/components/pages/chats/settings/whatsApp/number/ChatWhatsAppNumberTerms.vue'
import type { SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'
import { SenderNumbersMock } from '@/definitions/chats/settings/whatsApp/data'

export default defineComponent({
  components: {
    TmCountry,
    ChatsSettingsPage,
    DetailsHero,
    InfoCards,
    ChatWhatsAppNumberGeneral,
    ChatWhatsAppNumberSharing,
    ChatWhatsAppNumberTerms,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Messenger settings', name: 'base.chats.settings' },
      { label: 'WhatsApp', name: 'base.chats.settings.whatsApp' },
      { label: 'Nestle USA', name: 'base.chats.settings.whatsApp.detailed' },
      { label: '(345) 434-7654 ' },
    ]

    const numberLine = ref<SenderNumberLine>(SenderNumbersMock[0])

    return {
      breadcrumbs,
      numberLine,
    }
  },
})
